<template>
  <div class="app-selector">
    <div v-if="canViewGlobalApp" :class="['app', { selected: selectedAppId === null }]" @click="setSelectedApp(null)">
      <app-logo :class="[selectedAppId === null ? 'bg-grey' : 'bg-white']" :icon="allApps ? 'more' : 'universal'" />
      {{ allApps ? 'Toutes' : 'Global' }}
    </div>
    <div v-for="app in applications" :key="app.id" :class="['app', { selected: selectedAppId === app.id }]" @click="setSelectedApp(app.id)">
      <app-logo hide-tooltip :class="[selectedAppId === app.id ? 'bg-grey' : 'bg-white']" :application="app" />
      {{ app.name }}
    </div>
  </div>
</template>

<script>
  import useApplicationStore from '@/store/application.store'
  import { computed } from 'vue'
  import { globalProperties } from '@/main'

  export default {
    name: 'AppSelector',
    props: {
      allApps: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const applicationStore = useApplicationStore()
      const { applications, selectedAppId, setSelectedApp } = applicationStore

      const canViewGlobalApp = computed(() => globalProperties.roles.global?.includes('super-admin'))

      return { applications, selectedAppId, setSelectedApp, canViewGlobalApp }
    }
  }
</script>

<style lang="scss" scoped>
  .app-selector {
    margin-top: 2rem;
    height: 5.5rem;
    padding: 0 2rem;
    display: flex;
    max-width: stretch;
    overflow-y: scroll;
    .app {
      @extend %font-title-6;
      border: 2px solid transparent;
      min-width: 7.2rem;
      background: hsla(var(--hsla-black), 0.05);
      border-radius: 0.5rem;
      padding: 0.75rem;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 1.25rem;
      }
      &:hover {
        border-color: hsla(var(--hsla-second-accent), 0.25);
      }
      &.selected {
        border-color: var(--color-second-accent);
        background: var(--color-white);
      }
    }
  }
</style>
